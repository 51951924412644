<!-- 编辑社区简介 -->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="30%"
    @click="closeDialog"
    :z-index="12"
    :destroy-on-close="true"
    :close-on-click-modal="false"
  >
    <ax-form ref="form" :formBuilder="formBuilder1" @change="switchChange">
    </ax-form>
    <span slot="footer" class="dialog-footer">
      <el-button
        type="primary"
        @click="onSubmit"
        style="background-color: #556bff"
        :loading="saveType"
        >保 存</el-button
      >
      <el-button @click="onCancel">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
import api from "./api";
const formList1 = [
  {
    label: "话题名称",
    type: "",
    model: "residentName",
    options: { allowClear: true, placeholder: "请输入", maxLength: 20 },
    col: { span: 24 },
    rules: [{ required: true, message: "请输入话题名称" }],
  },
  {
    label: "排序",
    type: "inputNumber",
    model: "identityCard",
    options: {
      placeholder: "请输入数字，数字越小排序越靠前...",
      maxLength: 18,
    },
    col: { span: 24 },
    rules: [{ required: true, message: "请排序" }],
  },
  {
    label: "是否启用",
    type: "switch",
    model: "type",
    options: {
      allowClear: true,
      options: [
        { label: "本地居民", value: "0" },
        { label: "流入人口", value: "1" },
      ],
    },
    col: { span: 6 },
    rules: [{ required: true, message: "请选择人员类型" }],
  },
];
export default {
  components: {},
  data() {
    return {
      api,
      visible: false,
      saveType: false,
      title: "",
      formBuilder1: this.$common.initGridFormData(
        formList1,
        { layout: "horizontal", labelWidth: 120 },
        { col: { span: 8 } }
      ),
      isEnabled: "",
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
      this.title = record.title;
      this.showComments = false;
    },
    switchChange(e, type) {
      console.log(e, type);
      if (type === "type") {
        this.isEnabled = e;
      }
    },
    onSubmit() {},
    //关闭弹窗
    onCancel() {
      this.visible = false;
      this.$emit("refsh");
    },
    closeDialog() {
      this.visible = false;
      this.$emit("refsh");
    },
  },
};
</script>
    
<style lang="less" scoped>
/* 穿透区域 */
/deep/.el-dialog {
  border-radius: 20px !important;
}
/deep/.el-dialog__body {
  padding: 10px 40px;
}
/deep/.ant-switch-checked {
  background-color: #2a5cff;
}
</style>
    